body {
	overflow: visible !important;
}

.testtt {
	@apply pb-32 overflow-scroll;
	min-height: 1000px;
	font-size: 30px;
	overflow: scroll;
}
.testcrop {
	margin: 50px 0px;
	width: 450px;
	overflow: hidden;
	height: auto;
	aspect-ratio: 1 / 1;
	@apply relative;
	iframe {
		transform: translateY(0px);
		width: 100%;
	}
}

.chessboard {
	@apply max-w-md mx-auto;
}
.btn {
	@apply bg-green-800 text-gray-100 text-sm px-4 py-2 rounded-md hover:bg-green-700 active:bg-green-600 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-400 focus-visible:ring-offset-gray-100 dark:focus-visible:ring-offset-gray-900 dark:focus-visible:ring-green-300/75;
}

// jqx-widget-content jqx-widget-content-chessbase jqx-ribbon-content-section jqx-ribbon-content-section-chessbase jqx-ribbon-content-section-top jqx-ribbon-content-section-top-chessbase jqx-rc-b jqx-rc-b-chessbase

// .marker {
// 	@apply z-10 w-full h-12  bg-green-500 fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2;

// 	&:before {
// 		@apply w-3 h-full block content-[''] bg-purple-200 fixed left-1/2 transform -translate-x-1/2;
// 	}
// }

// .swiper-my-container {
// 	background-color: red !important;
// 	width: 100vw;
// 	height: 100vh;
// 	// position: relative;
// 	// height: auto;
// }

// div.swiper-slide {
// 	padding: 0 100px;
// 	width: fit-content;
// 	// background-color: blue;
// 	@apply bg-teal-200;
// }

// body {
// 	background: #eee;
// 	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
// 	font-size: 14px;
// 	color: #000;
// 	margin: 0;
// 	padding: 0;
// }

// .swiper {
// 	width: 100%;
// 	height: 100%;
// }

// .swiper-slide {
// 	text-align: center;
// 	font-size: 18px;
// 	background: #fff;

// 	/* Center slide text vertically */
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// }

// .swiper-slide img {
// 	display: block;
// 	width: 100%;
// 	height: 100%;
// 	object-fit: cover;
// }
