@import "../styles/clouds.scss";
@import "../styles/generics/breakpoints.scss";

.page_courrier {
	.clouds_1,
	.clouds_2 {
		pointer-events: none;
	}
	position: absolute;
	z-index: -2;
	// .displayedPicture,
	// .follower {
	// 	visibility: hidden;
	// }
	.indicationZone {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		&.is_hidden {
			display: none;
		}

		p {
			font-weight: 100;
			text-align: center;
			color: white;
			max-width: 200px;
			line-height: 28px;
		}
		video {
			max-height: 150px;
			mix-blend-mode: screen;
		}
	}

	overflow: hidden !important;
	min-height: 100vh;
	max-height: 100vh;
	width: 100vw;
	padding: 0;
	margin: 0;
	overflow: hidden !important;
	Nav {
		z-index: 1000;
	}
	&:has(Nav:hover),
	&:has(.cta_orangered:hover) {
		.follower {
			display: none;
		}
	}
}

.page_courrier.is_loading {
	.renardLoading {
		height: 220px;
	}
	.loading_container {
		color: white;
		font-family: "myoliver", serif;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

.titling {
	position: relative;
	z-index: 5;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 40px;

	@include max-w("small") {
		padding-top: 18vh;
	}

	h1 {
		font-family: "myoliver", serif;
		font-weight: 100;
		color: white;
		font-size: 30px;
		width: fit-content;
		padding: 0;
		letter-spacing: 1px;
	}
	.courrier_selection {
		position: absolute;
		z-index: 1000;
		display: flex;
		gap: 20px;
		bottom: 0px;
		transform: translate(0%, 150%);
	}
	.coeur {
		width: 50px;
	}
	.cta_orangered {
		margin: 0;
	}
}
.courrier_content_container {
	width: 100%;
	height: calc(88vh);
	cursor: crosshair;
	display: flex;
	justify-content: center;
	align-items: center;

	@include max-w("small") {
		top: 0;
		position: absolute;
		// padding-top: 80px;
		z-index: 0;
		padding-top: 30vh;
		align-items: start;
	}
}

.displayedPicture:nth-child(4n),
.follower.first {
	rotate: 3deg;
	background-image: url("../assets/img/courriers/fold_paper_18.webp");
}
.displayedPicture:nth-child(4n + 1),
.follower.second {
	rotate: -4deg;
	background-image: url("../assets/img/courriers/fold_paper_15.webp");
}
.displayedPicture:nth-child(4n + 2),
.follower.third {
	rotate: 6deg;
	background-image: url("../assets/img/courriers/fold_paper_9.webp");
}
.displayedPicture:nth-child(4n + 3),
.follower.fourth {
	// display: none;
	rotate: -10deg;
	// transform: rotate(-10deg);

	background-image: url("../assets/img/courriers/fold_paper_9.webp");
}

.displayedPicture {
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
	user-select: none;
	transition: transform 1s linear;
	z-index: -1;
	position: absolute;
	transform: translate(-50%, -20%);
	width: fit-content;
	height: fit-content;
	overflow: hidden;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
	background-size: cover;
	img {
		// max-width: 40vw;
		// max-height: 40vh;
		max-width: min(1000px, 50vw);
		max-height: min(800px, 80vh);
		mix-blend-mode: multiply;
		opacity: 90%;
	}
	.texture {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.follower {
	position: fixed;
	z-index: 0;
	transform: translate(-50%, -10%);
	img {
		-moz-user-select: -moz-none;
		-khtml-user-select: none;
		-webkit-user-select: none;
		-o-user-select: none;
		user-select: none;
		max-width: min(900px, 50vw);
		max-height: min(800px, 80vh);
		mix-blend-mode: multiply;
	}
	@media (max-width: 960px) and (hover: none) {
		display: none;
	}
}

.submitDrawing {
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	&:hover {
		transform: translateX(-50%) scale(1.2) !important;
		// background-color: red;
	}
	@media screen and (max-height: 600px) {
		bottom: 30px;
	}
}
