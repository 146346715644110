@font-face {
	font-family: "crunold";
	src: local("crunoldReg"), url("../../assets/fonts/Crunold-Regular.woff");
	font-weight: 100;
}
@font-face {
	font-family: "sherman";
	src: local("shermanReg"), url("../../assets/fonts/Sherman-Display.otf");
	font-weight: 100;
}
@font-face {
	font-family: "magicretro";
	src: url("../../assets/fonts/MagicRetroSlant.ttf");
	font-weight: 100;
}

@font-face {
	font-family: "din";
	src: url("../../assets/fonts/DINPro-Light.otf");
	font-weight: 100;
}
@font-face {
	font-family: "din";
	src: url("../../assets/fonts/DINPro-Regular.otf");
	font-weight: 200;
}
@font-face {
	font-family: "din";
	src: url("../../assets/fonts/DINPro-Medium.otf");
	font-weight: 300;
}
@font-face {
	font-family: "din";
	src: url("../../assets/fonts/DINPro-Bold.otf");
	font-weight: 400;
}
@font-face {
	font-family: "din";
	src: url("../../assets/fonts/DINPro-Black.otf");
	font-weight: 500;
}
@font-face {
	font-family: "myoliver";
	src: url("../../assets/fonts/Oliver-Regular.ttf");
}
@font-face {
	font-family: "neonblitz";
	src: url("../../assets/fonts/Neonblitz.woff");
}

.crunold {
	font-family: crunold, sans-serif;
}

$oliver: "myoliver", sans-serif;
$cru: "crunold", sans-serif;
$sherman: "sherman", sans-serif;
$magicretro: "magicretro", serif;
$din: "din", sans-serif;

p {
	font-family: $din, sans-serif;
	font-weight: 100;
	font-size: 20px;
}

span.oliver {
	font-family: "myoliver", sans-serif;
}
.oliver {
	font-family: "myoliver", sans-serif;
}
