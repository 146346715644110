.modal__wrapper--cassette {
	--plyr-audio-controls-background: transparent;
	--plyr-color-main: orangered;
	--plyr-badge-text-color: green;
	--plyr-control-toggle-checked-background: blue;
	--plyr-audio-control-color: white;

	background-color: #211f34;
	background-color: rgb(39, 39, 84);
	color: white;
	// z-index: 2;
	width: 100vw !important;
	max-width: 900px !important;
	padding: 50px 50px !important;
	.modal__content__close {
	}
	.blurry-background {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.7;
		// mix-blend-mode: overlay;
		z-index: 0;
	}
	// background-image: url("../blurred_abstract_backgrounds_05.jpg");
	.audio-player {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 1;
		opacity: 0.99;

		&__title {
			font-size: 46px;
			text-align: center;
			padding: 0 20px;
			max-width: 400px;
			line-height: 1;
			padding-bottom: 20px;
		}
		&__author-infos {
			font-size: 14px;
			padding-top: 20px;
			text-decoration: underline;
			text-underline-offset: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			.website-icon {
				margin-right: 4px;
				max-width: 20px;
			}
		}
		&__current-infos {
			padding: 30px 0;
		}
		&__current-track-index {
			text-align: center;
			font-weight: 400;
			font-size: 14px;
			padding-bottom: 10px;
		}
		&__current-track-name {
			font-size: 18px;
			font-weight: 400;
			text-transform: uppercase;
			text-align: center;
		}
		&__current-artist {
			font-size: 16px;
		}
		&__prevnext-buttons {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px;
			padding-bottom: 20px;
			button {
				border: 1px solid white;
				background-color: transparent;
				color: white;
				padding: 10px 20px;
				border-radius: 20px;
				&:hover {
					background-color: white;
					color: #191948;
				}
			}
		}
	}

	.skeud {
		max-width: 170px;
		animation: rotateskeud 5s linear infinite;
	}
	@keyframes rotateskeud {
		from {
			rotate: 0deg;
		}
		to {
			rotate: 360deg;
		}
	}
}

// rotate animation
// .plyr__control--overlaid {
// 	&:hover {
// 		transform: rotate(360deg);
// 	}

// .audio-player {
// }
