#iletadam {
	@apply relative;

	.video_container {
		&:after {
			width: 30vw;
		}
	}
	img {
		max-height: 200px;

		@apply m-auto w-auto;
	}
}
