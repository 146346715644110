body:has(.dialog__wrapper[open]) {
	overflow: hidden;
}
dialog {
	@apply mx-auto bg-transparent border-none;
	width: 100dvw;
	height: 100dvh;
	z-index: 1000;
	max-height: 100dvh;

	&::backdrop {
		background: rgba(0, 0, 0, 0.8);
	}
}

.dialog__wrapper {
	@apply relative;
	@apply py-24;
	@apply bg-transparent;
	@apply mx-auto;
	max-width: 900px;

	.blurry-background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		filter: blur(20px);
		z-index: 0;
		opacity: 0.96;
	}
}

.dialog__close-button {
	@apply cursor-pointer bg-transparent border-none;
	cursor: pointer;
	position: absolute;
	z-index: 9997;
	right: 20px;
	top: 20px;
	width: 32px;
	height: 32px;
	opacity: 0.7;
	transition: transform 0.3s ease-out;

	&:hover {
		filter: brightness(0.5) sepia(1) hue-rotate(0deg) saturate(10);
		opacity: 1;
		transform: scale(1.4);
	}
	&:before,
	&:after {
		@apply absolute top-0 left-1/2;
		transform: translate(0%, -50%);
		content: " ";
		height: 33px;
		width: 2px;
		background-color: #ffffff;
	}
	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
}
