@import "../../../styles/generics/typography";

#modaltadamgameinstruction {
	padding-top: 6vh;
	@media (min-height: 1100px) {
		padding-top: 15vh;
	}
	.text {
		opacity: 0.99;
	}
	color: white;
	p {
		padding-bottom: 16px;
		max-width: 450px;
		margin: 0 auto;
	}
	h4 {
		font-family: "myoliver";
		font-family: $magicretro;
		font-size: 60px;
		text-rendering: geometricPrecision;
		font-weight: 100;
		margin: 0;
		margin-bottom: 20px;
		margin: 0 auto;
		max-width: 700px;
		text-align: center;
		line-height: 1;
	}
	.modal__description {
		padding: 30px 0;
		max-width: 650px;
		margin: 0 auto;
		line-height: 1.5;
	}
	.arrow {
		filter: invert(1);
		height: 80px;
		transform: rotate(-180deg);
		margin: 0 auto;
		margin-bottom: -10px;
	}
	.arrow + p {
		max-width: 300px;
		margin: 0 auto;
		text-align: center;
	}
	.go {
		margin-top: 20px;
	}
	.download {
		margin: 0 auto;
		max-width: 50px;
		filter: invert(100%) sepia(200%) brightness(300%);
	}
	.cta--download {
		// transition: transform 0.2s ease-out;
		animation: none;
		margin-top: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 30px;
		padding-bottom: 0px;
		&:hover {
			transform: scale(1.1);
			background-color: orangered;
			color: white;
		}
	}
}
