/* On applique la propriété box-sizing pour que les propriétés width et height définissent la taille du bloc et non le contenu */
* {
	box-sizing: border-box;
}

/* Paramètres généraux de la page */
body {
	/* On utilise un système de grille */
	display: grid;
	/* On centre tout le contenu */
	place-items: center;
	/* Si un élément dépasse son conteneur, on le masque */
	// overflow: hidden;
}

/* Bloc principal pour tous les éléments */
.deal-wheel {
	/* Définition des variables du bloc */
	/* Taille de la roue */
	--size: clamp(250px, 80vmin, 700px);
	/* Paramètres de luminosité et de remplissage des secteurs */
	--lg-hs: 0 3%;
	--lg-stop: 50%;
	--lg: linear-gradient(hsl(var(--lg-hs) 0%) 0 var(--lg-stop), hsl(var(--lg-hs) 20%) var(--lg-stop) 100%);
	/* Positionnement relatif */
	position: relative;
	/* Utilisation de la grille */
	display: grid;
	grid-gap: calc(var(--size) / 20);
	/* Alignement des éléments au centre */
	align-items: center;
	/* Définition des zones dans la grille */
	grid-template-areas:
		"spinner"
		"trigger";
	/* Taille de la police */
	font-size: calc(var(--size) / 21);
}

/* Tous les éléments internes du bloc principal se trouvent dans la zone de la grille appelée "spinner" */
.deal-wheel > * {
	grid-area: spinner;
}

/* Le bloc et le bouton seront dans la zone "trigger" et seront centrés */
.deal-wheel .btn-spin {
	grid-area: trigger;
	justify-self: center;
}

/* Secteur de la roue */
.spinner {
	/* Positionnement relatif */
	position: relative;
	/* Utilisation de la grille */
	display: grid;
	/* Alignement au centre */
	align-items: center;
	/* Élément ajouté à la grille */
	grid-template-areas: "spinner";
	/* Dimensions */
	width: var(--size);
	height: var(--size);
	/* Rotation de l'élément */
	transform: rotate(calc(var(--rotate, 25) * 1deg));
	/* Forme circulaire avec contenu caché hors du cercle */
	border-radius: 50%;
}

/* Tout ce qui est dans ce bloc sera dans la zone "spinner" */
.spinner * {
	grid-area: spinner;
}

/* Texte des secteurs */
.prize {
	/* Flexbox activé */
	display: flex;
	align-items: center;
	/* Marges internes */
	padding: 0 calc(var(--size) / 6) 0 calc(var(--size) / 20);
	/* Dimensions */
	width: 50%;
	height: 50%;
	/* Point d'origine pour la rotation du texte */
	transform-origin: center right;
	/* Rotation du texte */
	transform: rotate(var(--rotate));
	/* Désactivation de la sélection du texte */
	user-select: none;
}

/* Indicateur */
.ticker {
	/* Positionnement relatif */
	position: relative;
	/* Dimensions */
	left: calc(var(--size) / -15);
	width: calc(var(--size) / 10);
	height: calc(var(--size) / 20);
	/* Fond de l'indicateur */
	background: var(--lg);
	/* Positionnement devant la roue */
	z-index: 1;
	/* Forme de l'indicateur */
	clip-path: polygon(20% 0, 100% 50%, 20% 100%, 0% 50%);
	/* Point d'origine pour la rotation de l'indicateur */
	transform-origin: center left;
}

/* Bouton de lancement de la roue */
.btn-spin {
	color: white;
	background: black;
	border: none;
	/* Taille de la police identique à celle de la roue */
	font-size: inherit;
	/* Marges internes */
	padding: 0.9rem 2rem 1rem;
	/* Arrondi des coins */
	border-radius: 0.5rem;
	/* Apparence du curseur sur le bouton */
	cursor: pointer;
}

/* Si le bouton est désactivé */
.btn-spin:disabled {
	/* Changement du curseur */
	cursor: progress;
	/* Apparence semi-transparente */
	opacity: 0.25;
}

/* Animation de la rotation */
.is-spinning .spinner {
	transition: transform 8s cubic-bezier(0.1, -0.01, 0, 1);
}

/* Animation de l'indicateur */
.is-spinning .ticker {
	animation: tick 700ms cubic-bezier(0.34, 1.56, 0.64, 1);
}

/* Effet lorsque la roue touche l'indicateur lors de la rotation */
@keyframes tick {
	40% {
		/* Rotation légère vers le haut en milieu d'animation */
		transform: rotate(-12deg);
	}
}

/* Animation du secteur sélectionné */
.prize.selected .text {
	/* Couleur blanche pour le texte */
	color: white;
	/* Durée de l'animation */
	animation: selected 800ms ease;
}

/* Animation du texte du secteur sélectionné */
@keyframes selected {
	25% {
		/* Agrandissement du texte */
		transform: scale(1.25);
		/* Ajout d'une ombre au texte */
		text-shadow: 1vmin 1vmin 0 hsla(0 0% 0% / 0.1);
	}
	40% {
		transform: scale(0.92);
		text-shadow: 0 0 0 hsla(0 0% 0% / 0.2);
	}
	60% {
		transform: scale(1.02);
		text-shadow: 0.5vmin 0.5vmin 0 hsla(0 0% 0% / 0.1);
	}
	75% {
		transform: scale(0.98);
	}
	85% {
		transform: scale(1);
	}
}
