/* .animated-button {
	@apply relative z-10 bg-red-400;
	z-index: 100;
	position: relative;
	top: 200px;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	overflow: visible;
}

.button-animation {
	@apply absolute top-0 left-0 w-80 h-80 z-10;
	pointer-events: none;
} */
.animated-button-container {
	@apply relative;
}
.animation-trigger {
	@apply border-none p-8 mx-auto block my-32;
}

.event-animation {
	@apply absolute  left-1/2 top-1/2  w-80 h-80 z-10;
	mix-blend-mode: screen;
	transform: translate(-50%, -50%);
	//changer le centre
	transform-origin: center;
	pointer-events: none;
}
