.tadam-section--playlist-louison {
	@apply pt-32 pb-44 min-h-screen px-8;
	max-width: 100vw;
	h2 {
		@apply pt-6;
	}

	.guitare {
		@apply absolute top-0 hidden md:block;
		width: auto;
		height: 100%;
		pointer-events: none;
		z-index: -1;

		&--left {
			@apply left-0;
		}
		&--right {
			@apply right-0;
			transform: scaleX(-1);
			-webkit-transform: scaleX(-1);
		}
	}

	.background-circle {
		@apply absolute top-0 left-0 block bg-no-repeat bg-center mx-auto;
		@apply w-full h-full;
		/* width: 130%;
		height: 150%; */
		background-size: 80% 80%;
		background-image: url("/assets/img/tadam/textures/f3-louison.webp");
		z-index: -2;
	}

	.player {
		@apply w-full  z-50 max-w-lg mx-auto relative h-auto mt-24;
		@apply bg-white rounded-xl xl:px-12 pt-32;
		background: #f8d5c8;

		aspect-ratio: 1325/2255;
		@apply text-center;

		.profile-pic {
			max-height: 30%;
			width: auto;
		}
		.phone-wrapper {
			@apply absolute top-0 left-1/2 w-full h-full;
			width: 108%;
			height: 103%;
			z-index: -1;
			transform: translateX(-50%) translateY(-10px);
		}
	}

	.audio-player {
		@apply px-10;
		&__current-infos {
			@apply text-tadamblue text-4xl;
		}
		&__current-track-name {
			@apply text-tadamblue text-4xl;
			font-family: crunold, sans-serif;
		}
		&__current-artist {
			@apply text-xl pt-1 pb-6;
			line-height: 1 !important;
		}

		&__author-infos {
			@apply text-sm  md:text-base text-blue-600 pt-4;
			line-height: 1.2;

			.author {
				@apply inline items-center justify-center underline underline-offset-4;
			}
		}
		&__current-track-credits {
			@apply text-sm text-tadamblue p-6;
		}

		&__control-wrapper {
			@apply bg-white rounded-xl md:px-12 py-2;
		}

		&__prevnext-buttons {
			@apply flex justify-center items-center gap-2 mb-2;
			button {
				@apply bg-tadamblue text-tadamblue px-10 py-3 rounded-xl;
				/* border: 1px solid theme("colors.tadamblue"); */
				background-color: transparent;
				border: none;
				@apply px-3;

				svg {
					@apply w-8 h-8 fill-tadamblue;
				}
				&:hover {
					@apply bg-tadamblue text-white;
					svg {
						@apply fill-white;
					}
				}
			}
		}
	}
	.playlist-title {
	}

	/* .plyr__controls {
		@apply flex justify-center items-center  rounded-xl flex-wrap;
	} */

	.plyr__progress__container,
	.plyr__progress {
		/* @apply w-full; */
	}

	.plyr__control[data-plyr="fullscreen"],
	.plyr__menu {
		@apply hidden;
	}
	.plyr--full-ui input[type="range"] {
		--plyr-range-thumb-height: 20px; // Taille du point
		--plyr-range-thumb-width: 20px;
		--plyr-range-track-height: 20px; // Hauteur de la barre
	}
	/* .plyr__time--duration,
	.plyr__time--current {
		@apply w-full;
	} */

	.plyr__progress__container {
		flex: auto;
	}

	// Si nécessaire, ajustez le conteneur parent
	--plyr-range-track-height: 20px;
	--plyr-control-icon-size: 30px;
	--plyr-audio-controls-background: white;

	/* --plyr-audio-controls-background: theme("colors.yellow.400") !important; */
	--plyr-range-fill-background: white;
	--plyr-color-main: #e8856b;
	--plyr-audio-control-color: theme("colors.tadamblue");
	/* --plyr-range-fill-background: theme("colors.red.500"); */
	--plyr-range-fill-background: #e8856b;
	--plyr-audio-progress-buffered-background: #e8856b20;
}

/* .plyr__menu {
	@apply hidden;
} */
