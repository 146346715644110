.cta {
	width: fit-content;
	transition: transform 0.4s ease-out;
	position: relative;
	flex-wrap: wrap;
	cursor: pointer;
	display: block;
	justify-items: center;
	align-items: center;
	border: 1px solid white;
	border-radius: 80px;
	color: white;
	text-decoration: none;
	// @apply crunold;
	font-family: "crunold", sans-serif;
	text-rendering: geometricPrecision;
	letter-spacing: 1px;
	font-weight: 100;
	width: fit-content;
	margin-top: 60px;
	padding-right: 40px;

	animation: button_apparition 1.2s ease-out;
	animation-delay: 2s;
	animation-fill-mode: backwards;

	.text {
		font-weight: 800;
		text-transform: uppercase;
		display: block;
		flex-shrink: 0;
		letter-spacing: 2px;
		padding: 16px 30px 16px 30px;
	}
	.round {
		&:before {
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			height: 200%;
			width: 200%;
			transform: translateY(-50%) translateX(-50%);
			// @apply bg-red-500;
		}
		position: absolute;
		transition-property: width border-radius;
		transition: 0.3s ease-out;
		right: 0;
		top: 0;
		width: 56px;
		// aspect-ratio: 1/1;
		height: 100%;
		background-color: white;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		.arrow {
			border-right: 2px solid;
			border-top: 2px solid;
			border-color: #6fc1de;
			transform: translateX(-10%) rotate(45deg);
			height: 8px;
			width: 8px;
		}
	}
}

.cta--white {
	background-color: white;
	border-radius: 80px;
	border: none;
	padding: 10px 20px;
	transition: transform 0.3s ease-out;
	cursor: pointer;
	color: blue;
	color: orangered;
	text-transform: uppercase;
	font-weight: 300;
	letter-spacing: 0.7px;
	&:hover {
		color: white;
		transform: scale(1.3);
		background-color: orangered;
	}
}
.cta--orangered {
	background-color: orangered;
}

.cta--blue {
	width: fit-content;
	cursor: pointer;
	display: block;
	border-radius: 80px;
	color: white;
	background-color: blue;
	text-decoration: none;
	text-transform: uppercase;
	font-family: "din", sans-serif;
	font-size: 14px;
	text-rendering: geometricPrecision;
	letter-spacing: 1px;
	font-weight: 400;
	width: fit-content;
	padding: 10px 40px;
	margin: 20px auto;
	transition: transform 0.4s ease-out;
}

.cta--blue:hover {
	transform: translateY(-2px) scale(1.3);
}

.cta:hover {
	// transform: translateY(-8px);
	border: none;
	.round {
		right: 0;
		top: 0;
		border-radius: 80px;
		width: 100%;
		background-color: orangered;
		&:before {
			transform: translateY(-50%) translateX(0%);
			width: 100%;
		}
		.arrow {
			border-color: white;
		}
	}
}

.cta_orangered {
	cursor: pointer;
	display: block;
	border: none;
	background-color: orangered;
	color: white;
	padding: 20px 40px;
	border-radius: 40px;
	// font-family: "crunold", sans-serif;
	font-family: "din", sans-serif;
	font-weight: 300 !important;
	letter-spacing: 2px;
	font-size: 13px;
	width: fit-content;
	margin: 0 auto;
	text-transform: uppercase;
	transition: 0.4s ease-out;

	&:hover {
		background-color: white;
		color: orangered;
		transform: scale(1.2);
	}
}

.cta-arrowed {
	cursor: pointer;
	display: block;
	border: none;
	padding: 20px 40px;
	border-radius: 40px;
	font-family: "din", sans-serif;
	font-weight: 300 !important;
	letter-spacing: 2px;
	font-size: 13px;
	width: fit-content;
	margin: 0 auto;
	text-transform: uppercase;
	transition: 0.4s ease-out;
	position: relative;
	&--orangered {
		background-color: orangered;
		color: white;
	}
	&--blue {
		background-color: blue;
		color: white;
	}
	&::after {
		transition: 0.3s ease-out;
		opacity: 0;
		content: "";
		display: block;
		width: 40px;
		height: 12px;
		position: absolute;
		right: 30px;
		top: 50%;
		transform: translateY(-50%) rotate(2deg);
		background: url("/assets/svg/arrow_long.svg") no-repeat center;
		filter: invert(200%);
		background-size: contain;
	}
	&:hover {
		padding: 20px 80px 20px 40px;
		&::after {
			opacity: 1;
			right: 30px;
		}
	}
}

.cta_orange_arrow {
	@apply cta_orangered;
	background-color: red;
	display: block;
	background-color: orangered;
	color: white;
	padding: 20px 40px;
	border-radius: 40px;
	font-family: "din", sans-serif;
	font-weight: 300;
	letter-spacing: 2px;
	font-size: 14px;
	width: fit-content;
	margin: 0 auto;
	text-transform: uppercase;
	transition: 0.4s ease-out;

	&:hover {
		transform: scale(1.05);
	}
}

.button {
	cursor: pointer;
	border: none;
	padding: 10px 20px;
	font-family: "din", sans-serif;
	font-size: 14px;
	font-weight: 300;
	transition: all 0.2s ease-out;
	&[disabled] {
		cursor: not-allowed;
	}
	&:hover {
		transform: scale(1.2);
	}
	&.is_current {
		opacity: 1;
	}
	&.is_not_current {
		opacity: 0.5;
		&:hover {
			opacity: 1;
		}
	}

	.button_icon {
		max-width: 40px;
		padding-right: 10px;
	}
	&--oliver {
		font-family: "myoliver", serif;
		font-size: 16px;
	}
	&--centered {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&--white {
		background-color: white;
		color: blue;
	}
	&--blue {
		background-color: blue;
		color: white;
	}
	&--orange {
		background-color: orangered;
		color: white;
	}
	&--rounded {
		border-radius: 30px;
	}
}

// CTA RONDS QUI VIBRENT
.round-button {
	min-width: 100px;
	aspect-ratio: 1/1;
	border-radius: 50%;
	color: white;
	border: none;
	font-family: "myoliver";
	font-size: 20px;
	cursor: pointer;
	margin: 10px;
	transition: scale 0.3s ease-out;
	&:hover {
		animation: shake3856 0.8s linear infinite both;
		filter: brightness(1.2) hue-rotate(-20deg) saturate(3.5);
		scale: 1.2;
	}
	&--blue {
		background-color: blue;
	}
	&--orange {
		background-color: orange;
	}
	&--orangered {
		background-color: orangered;
	}
}

@keyframes shake3856 {
	0% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}

	20% {
		-webkit-transform: translate(-2px, 2px);
		transform: translate(-2px, 2px);
	}

	40% {
		-webkit-transform: translate(-2px, -2px);
		transform: translate(-2px, -2px);
	}

	60% {
		-webkit-transform: translate(2px, 2px);
		transform: translate(2px, 2px);
	}

	80% {
		-webkit-transform: translate(2px, -2px);
		transform: translate(2px, -2px);
	}

	100% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
}
