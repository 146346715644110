body {
	overflow-x: hidden;
}

.page_container {
	overflow: hidden;
	position: relative;
	// @apply flex justify-center items-center;
}
@import "../styles/clouds.scss";
.marker {
	@apply z-10 w-full h-12  bg-green-500 fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2;

	&:before {
		@apply w-3 h-full block content-[''] bg-purple-200 fixed left-1/2 transform -translate-x-1/2;
	}
}
@media (width <600px) {
	.clouds_1,
	.clouds_2 {
		display: none;
	}
}
.islands_container {
	// background-color: rgba(255, 11, 11, 0.485) !important;
	width: 100vw;
	height: 100vh;

	// SWIPER
	.swiper-container {
		position: static;
		// @apply bg-green-100 p-12;
	}

	// 	// SLIDES
	div.swiper-slide {
		@apply flex items-center justify-center h-screen;
		// width: 100vw;
		// box-sizing: content-box;
		width: fit-content;
		padding: 0;
		margin: 0 14vw;
		// min-width: 80vw;
		// margin: 0 14vw;
		&:nth-child(2n) {
			// @apply bg-purple-400;
		}
		// 		display: flex;
		// 		align-items: center;
		// 		justify-content: center;
		height: 100vh;

		max-width: 900px;
		padding-bottom: 20px;

		&:has(.small_island) {
			width: 20vw;
			max-width: 700px;
		}
		@media (width < 900px) {
			width: 90%;
			&:has(.small_island) {
				width: fit-content;
				max-width: 100%;
			}
		}
	}

	.Island,
	.small_island {
		@apply cursor-pointer flex;

		// background-color: green;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

		.text {
		}
		&:hover {
			.text {
				h2.island_title {
					transform: skew(-9deg) scale(1.1);
					color: rgb(255, 255, 255);
				}
				.breakDown {
					letter-spacing: 5px;
				}
			}

			.video_container {
				scale: 1.1;
			}
		}
	}
	.video_container {
		display: block;
		max-height: 600px;
		height: 60vh;
		width: 100%;
		@media (width < 900px) {
			height: 40dvh;
		}

		display: flex;
		justify-content: center;
		animation: floating_islands 1.8s ease-out infinite alternate;
		transition: 0.4s ease-out;

		&:after {
			content: "";
			width: 100%;
			height: 100%;
			transform: translate(-50%, -50%);
			display: block;
			background: radial-gradient(rgb(200, 252, 251), transparent 60%);
			position: absolute;
			top: 50%;
			z-index: -1;
			left: 50%;
			// mix-blend-mode: soft-light;
			opacity: 0.2;
		}
		video,
		img {
			// max-width: 100%;
			max-height: 100%;
			margin-top: auto;
			filter: saturate(0.95);
			@media (width < 1000px) {
				max-height: unset;
				width: 100%;
				height: 100%;
			}
		}
	}
	.small_island {
		.video_container {
			height: fit-content;
			padding-bottom: 20px;
		}
		.text {
			transform: rotate(-4deg) skew(-4deg);

			h2.island_title {
				font-size: 54px;
				letter-spacing: 8px;
			}
			p {
				max-width: 250px;
			}
		}
	}
	.text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	// CONTENT
	h2.island_title {
		text-align: center;
		color: white;
		font-family: crunold, sans-serif;
		font-family: crunold, sans;
		text-transform: uppercase;
		text-rendering: optimizeLegibility;
		font-size: 78px;
		font-size: clamp(2rem, 8vw, 78px);
		letter-spacing: 8px;
		margin: 0;
		font-weight: 100;
		line-height: 1;
		transition: 0.4s ease-out;
		text-align: center;
	}
	span.breakDown {
		font-family: "myoliver";
		font-family: "crunold", "din", sans-serif;
		font-size: 30px;
		letter-spacing: 4px;
		display: block;
		font-weight: 100;
		font-weight: 300;
		line-height: 0.8;
		letter-spacing: 4px;
		transition: 0.4s ease-out;
	}

	p {
		color: white;
		padding-top: 10px;
		line-height: 1.4;
		font-family: "din", sans-serif;
		font-size: 18px;
		font-weight: 200;
		max-width: 450px;
		text-align: center;
	}
}
.swiper-pagination {
	bottom: 20px !important;
	@media (height < 600px) {
		bottom: 15dvh !important;
	}
}

.swiper-pagination-bullet {
	background-color: white !important;
	opacity: 0.8;
	transform: scale(2.8);
	width: 14px;
	height: 14px;
}
.swiper-pagination-bullet-active {
	background-color: orangered !important;
}
.swiper-cta-prev,
.swiper-cta-next {
	@apply flex justify-center items-center;
	@apply fixed w-auto;

	z-index: 100;
	top: 50%;
	border: none;
	height: 80px;

	background-color: transparent;
	aspect-ratio: 1/1;
	border-radius: 50%;
	transition: all 0.2s ease-out;

	&.disabled {
		opacity: 0.2;
	}
	&:hover {
		transform: scale(120%);
		border: 1px solid white;
	}
	img {
		width: 20px;
	}
	@media (width < 900px) {
		display: flex;
		justify-content: center;
		align-items: center;
		top: 90vh;
		background-color: orangered;
		height: 50px;
		img {
			max-width: 20%;
		}
	}
	@media (height < 600px) {
		top: unset;
		bottom: 50dvh;
		height: 40px;
	}
}
.swiper-cta-prev {
	left: 20vw;
	@media (width < 900px) {
		left: 20px;
	}
	@media (width < 900px) {
		left: 10px;
	}
}
.swiper-cta-next {
	right: 20vw;
	rotate: 180deg;
	@media (width < 900px) {
		right: 20px;
	}
	@media (width < 900px) {
		right: 10px;
	}
}

/* ########### CLOUDS  ########### */
@keyframes cloud_homeisland_apparition_1 {
	from {
		opacity: 0;
		transform: scaleX(-1) translate(100%, 20%);
	}
	to {
		opacity: 1;
		transform: scaleX(-1) translate(50%, 20%);
	}
}
@keyframes cloud_homeisland_apparition_2 {
	0% {
		opacity: 0;
		transform: scaleY(-1) translate(100%, 50%);
	}
	100% {
		opacity: 1;
		transform: scaleY(-1) translate(50%, 50%);
	}
}
