@import "~styles/generics/typography";

.tadam-section--question-wheel {
	@apply pb-32 py-24 relative;
}
.completed-questions-container {
	@apply text-center;
	padding: 0 !important;
	.completed-questions-label {
		@apply font-normal;
	}
}

.wheel-container {
	@apply relative  w-fit mx-auto;

	.wheel-habillage {
		@apply relative z-10;
		height: 56vh;
		width: auto;
		max-height: 650px;
	}
	.wheel-inner-circle {
		@apply absolute  block left-1/2 z-0;
		top: 14%;
		translate: -50% 0;
		width: 88%;
		aspect-ratio: 1;
		border-radius: 50%;
		background: repeating-conic-gradient(orangered 0 45deg, #1226b0 45deg 90deg);
		transition: transform 2.6s cubic-bezier(0.191, -0.006, 0.307, 1.004);
	}
	.question-box {
		@apply fixed top-1/2 left-1/2  z-50 bg-purple-100 border-none;
		// transform: translate(-50%, -50%);
		height: calc(100vh - 2rem);
		width: calc(100vw - 2rem);
		transform: translate(-50%, -50%);
		background: rgba(18, 38, 176, 1);
		.close-button {
			@apply absolute top-2 right-2;

			@apply p-2;
			@apply rounded-full;
			@apply cursor-pointer;

			@apply bg-white w-10 h-10 flex justify-center items-center text-tadamblue;
			/* color: orangered; */
			border: 2px solid theme("colors.tadamblue");
		}
		.question-content {
			@apply absolute bg-white rounded-xl p-12 text-renardblue;
			width: 80%;
			max-width: 600px;
			@apply top-1/2 left-1/2 z-50;
			transform: translate(-50%, -50%);
			p {
				@apply text-xl md:text-3xl lg:text-5xl text-center;
				font-family: $magicretro;
			}
		}
	}
	.circle-cloud-animation {
		@apply absolute top-1/2 left-1/2 z-30  w-full h-full;
		scale: 1.7;
		rotate: 100deg;
		translate: -49% -62%;
	}
}
.spin-button {
	@apply bg-renardred
	block 
	text-white
	text-lg 
	font-light  
	uppercase  
	py-3 
	px-8 
	rounded-2xl 
	mx-auto
	border-none relative z-40
	-mt-20
	bg-tadamblue
	tracking-widest;

	transition: all 0.6s cubic-bezier(0.191, -0.006, 0.307, 1.004);

	&--restart {
		@apply bg-emerald-600;
	}
	&:before {
		content: "→";
		@apply absolute opacity-0 top-1/2 left-0 w-8 h-8;
		transform: translate(0%, -50%);
		transition: all 0.6s cubic-bezier(0.191, -0.006, 0.307, 1.004);
	}
	span {
		@apply block;
		transition: all 0.3s cubic-bezier(0.191, -0.006, 0.307, 1.004);
	}
	&:hover {
		scale: 1.25;
		background-color: orangered;
		span {
			transform: translate(18px, 0);
		}
		&:before {
			transform: translate(16px, -50%);
			@apply opacity-100;
		}
	}
	&:disabled {
		@apply bg-gray-400 opacity-60;
	}
}
.tadam-section--question-wheel--yann {
	.colonne {
		@apply absolute top-0 w-fit h-full hidden md:block;
		z-index: -1;
		&--droite {
			@apply right-0;
		}
		&--gauche {
			@apply left-0;
			-webkit-transform: scaleX(-1);
			transform: scaleX(-1);
		}
	}
}
.tadam-section--question-wheel--kiki {
	.vignette {
		@apply hidden md:block absolute top-0 left-0 w-full h-full;
		z-index: -1;
	}
}
.tadam-section--question-wheel--louison {
	.eclair {
		@apply absolute top-0 hidden md:block;
		height: 60vh;
		// max-height: 600px;
		width: auto;
		z-index: -1;

		&--gauche {
			@apply left-0;
			transform: scaleX(-1);
			-webkit-transform: scaleX(-1);
		}
		&--droite {
			@apply right-0;
		}
	}
}
