.tadam-section--tutos-yann {
	@apply relative min-h-screen pb-32 overflow-hidden;
	h2 {
		@apply text-center w-fit mx-auto mt-44 pb-20 z-10;

		&:before,
		&:after {
			@apply bottom-0;
		}
	}
	.video {
		@apply mx-auto relative;
		aspect-ratio: 16/9;
		max-height: 50vh;

		iframe {
			@apply w-full h-full border-2 border-gray-300 rounded-lg;
		}
	}
	.background-circle {
		@apply absolute top-0 block bg-no-repeat bg-center mx-auto;
		@apply left-1/2;
		transform: translate(-50%, -50%);
		width: 130%;
		height: 150%;
		background-size: 100% 100%;
		background-image: url("/assets/img/tadam/textures/f1-yann.webp");
		z-index: -1;
	}
	.ostheopate {
		@apply absolute -bottom-24 -left-3  w-80 h-auto pointer-events-none;
		transform: translate(-50%, 0);
	}
	.rideau {
		@apply absolute top-0 h-screen 2xl:h-[110vh] w-auto hidden md:block;

		&--left {
			@apply left-[-10%] lg:left-[-6%] xl:left-[-4%] absolute;
		}
		&--right {
			@apply right-[-10%] lg:right-[-6%] xl:right-[-4%] absolute;
			-webkit-transform: scaleX(-1);
			transform: scaleX(-1);
		}
	}

	.star {
		@apply absolute top-0 w-36;
		&--left {
			@apply left-0;
		}
		&--right {
			@apply right-0;
			-webkit-transform: scaleX(-1);
			transform: scaleX(-1);
		}
	}
}
