@import "~styles/generics/typography";

.tadam-section--character-interaction {
	@apply pb-32 py-24;
}

.section-title {
	@apply pb-12;
}

.character-container {
	@apply relative w-full mx-auto  bg-center bg-no-repeat bg-contain flex justify-center;

	.bullet-cloud-animation {
		@apply fixed  z-30  w-32 h-32;
		transform: translate(-50%, -50%);

		z-index: 100;
	}
	.picture-container {
		@apply w-fit relative h-fit;

		.character {
			height: 80vh;
			max-height: 800px;
		}
		.character-background {
			@apply absolute top-0 left-0 w-full h-full;
			transform: scale(1.8, 1.2);
			background-size: 100% 100%;
		}

		&:after {
			content: "";
			position: absolute;
			@apply top-1/2 left-1/2;
			transform: translate(-50%, -50%);
			width: 200%;
			height: 110%;
			background-size: 100% 100%;
			z-index: 0;
		}
	}

	.bullet {
		@apply bg-none z-50 absolute block;

		.bullet-btn {
			@apply block static  bg-tadamblue  border-0 opacity-80   w-6 h-6 rounded-full z-50;
			transition: all 0.3s;
			transform: translate(-50%, -50%);
			// transform: translate(-50%, -50%);
			/* background-color: orangered; */

			&.active {
				@apply bg-tadamblue;
				@apply w-12 h-12;

				&:after {
					@apply w-12 h-12 rounded-full;
					@apply bg-white animate-ping;
					content: "";
					display: inline-block;

					background: white;
				}
			}

			&:hover {
				@apply bg-white;
				filter: blur(2px);

				@apply w-12 h-12;
			}
		}
		.bullet-explanation {
			@apply absolute hidden top-0 w-80;
			color: #1226b0;

			@media screen and (max-width: 768px) {
				@apply bg-white;
				left: 50% !important;
				@apply fixed  p-6;
				left: unset !important;
				right: unset !important;
				top: unset !important;
				bottom: unset !important;
				top: 50% !important;
				left: 50% !important;
				transform: translate(-50%, -50%);
				/* transform: translate(-50%, 0%); */
				z-index: 200;
			}

			@screen md {
				transform: translateY(-50%);
				&.is-left {
					@apply right-32;
				}
				&.is-right {
					@apply left-32;
				}
			}

			h3 {
				@apply text-5xl font-normal;
				font-family: "magicretro", serif;
			}
		}

		&.active .bullet-explanation {
			@apply block;
			@screen md {
				animation: fadeIn 0.4s ease-in, slideIn 0.6s cubic-bezier(0, 0.303, 0.046, 1.01);
			}
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
			// transform: translateY(-60%);
		}
		to {
			opacity: 1;
			// transform: translateY(-50%);
		}
	}
	@keyframes slideIn {
		from {
			transform: translateY(-60%);
		}
		to {
			transform: translateY(-50%);
		}
	}
	.text-content {
		@apply top-0 left-0  flex flex-col justify-center items-center z-40;
		// @apply hidden;
		padding-left: 40px;
		padding-top: 50px;

		&__explanation {
			@apply absolute hidden;
			color: #f1c98d;

			&:nth-child(odd) {
				@apply 2xl:right-[20%] right-6;
			}
			&:nth-child(even) {
				@apply text-right 2xl:left-[10%] left-6;
			}

			max-width: 400px;
			margin: 20px 0;

			&.active {
				@apply block;
			}
		}
	}

	img {
		@apply relative w-fit z-10 block;
		max-height: 80vh;
	}
}
.character-background--yann {
	background-image: url("/assets/img/tadam/textures/f1-yann.webp");
}
.character-background--louison {
	background-image: url("/assets/img/tadam/textures/f2-louison.webp");
}
.character-background--kiki {
	background-image: url("/assets/img/tadam/textures/f1-kiki.webp");
}
