@import "../styles/generics/typography";

#page-tadam {
	position: relative;
	background-color: none !important;
	.navbar {
		z-index: 80;
	}
	.magic-smoke {
		@apply w-screen h-screen fixed top-0 left-0;
		transform: rotate(180deg);
		z-index: 60;
		pointer-events: none;
	}
	h1 {
		font-family: $magicretro;
		@apply text-tadamblue text-7xl 2xl:text-8xl;
	}

	.tadam-section {
		@apply relative;
		&__title {
			@apply text-tadamblue;
			@apply text-center text-5xl xl:text-7xl max-w-screen-md mx-auto;
		}

		&__description {
			@apply text-tadamblue text-2xl max-w-screen-sm px-12 py-6 mb-6 mx-auto text-center;
		}
	}

	.frise-damier {
		@apply h-20 w-full z-10 relative;
		background-color: transparent;
		background-repeat: repeat-x;
		background-size: auto 100%;
		background-position: center;
		@apply -my-4;

		&--louison {
			background-image: url("../assets/img/tadam/frise_damier_louison.webp");
		}
		&--yann {
			background-image: url("../assets/img/tadam/frise_damier_yann.webp");
		}
		&--kiki {
			background-image: url("../assets/img/tadam/frise_damier_kiki.webp");
		}
	}
	.backround {
		@apply top-1/2 left-1/2 w-1/2 h-1/2 absolute;
		z-index: -1;
		transform: translate(-50%, -50%);
	}
}

h2 {
	@apply text-5xl font-normal text-tadamblue;
	font-family: $magicretro;
}

h2.has-sparkle {
	@apply relative;
	&:before {
		content: "";
		@apply absolute top-0 left-1/2 w-20 h-20 bg-no-repeat bg-center bg-contain;
		transform: translate(-50%, -100%);
		z-index: -1;
		background-image: url("/assets/img/tadam/star.webp");
	}
}

.video-pattern {
	@apply absolute top-0 left-0 w-full h-full;
	object-fit: cover;
	z-index: -2;
}
.consignes {
	z-index: 100;
}
.page-tadam {
	.background-image {
		opacity: 70%;
	}
	.video-pattern--damier-1 {
		opacity: 16%;
	}
	.video-pattern--damier-2 {
		opacity: 16%;
	}
	.video-pattern--spiral {
		opacity: 16%;
	}
}

div.background-image {
	@apply absolute top-0 left-0 w-full h-full;
	opacity: 10%;
	z-index: -5;
}

.page-tadam--yann {
	cursor: url("/assets/img/tadam/cursors/cursor-yann-32x32-normal.png"), auto;
	a:hover,
	a:active,
	button:hover,
	button:active {
		cursor: url("/assets/img/tadam/cursors/cursor-yann-32x32-hover.png"), pointer;
	}
	.background-image {
		background-image: url("../assets/img/tadam/textures/f0-yann.webp");
	}
	.video-pattern--damier-1 {
		filter: brightness(2.6) sepia(1) saturate(140%) hue-rotate(206deg) contrast(0.8);
	}
	.video-pattern--damier-2 {
		filter: sepia(1) saturate(120%) hue-rotate(210deg);
	}
	.video-pattern--spiral {
		filter: brightness(0.9) sepia(1) saturate(110%) hue-rotate(206deg);
	}
}

.page-tadam--louison {
	cursor: url("/assets/img/tadam/cursors/cursor-louison-32x32-normal.png"), auto;
	a:hover,
	a:active,
	button:hover,
	button:active {
		cursor: url("/assets/img/tadam/cursors/cursor-louison-32x32-hover.png"), pointer;
	}
	.background-image {
		background-image: url("../assets/img/tadam/textures/f0-louison.webp");
	}
	.video-pattern--damier-1 {
		filter: brightness(2.6) sepia(1) saturate(140%) hue-rotate(320deg) contrast(0.8);
	}
	.video-pattern--damier-2 {
		filter: sepia(1) saturate(120%) hue-rotate(310deg);
	}
	.video-pattern--spiral {
		filter: brightness(0.9) sepia(1) saturate(110%) hue-rotate(320deg);
	}
}
.page-tadam--kiki {
	cursor: url("/assets/img/tadam/cursors/cursor-kiki-32x32-normal.png"), auto;
	a:hover,
	a:active,
	button:hover,
	button:active {
		cursor: url("/assets/img/tadam/cursors/cursor-kiki-32x32-hover.png"), pointer;
	}

	.background-image {
		background-image: url("../assets/img/tadam/textures/f0-kiki.webp");
	}
	.video-pattern--damier-1 {
		filter: brightness(2.6) sepia(1) saturate(140%) hue-rotate(100deg) contrast(0.8);
	}
	.video-pattern--damier-2 {
		filter: sepia(1) saturate(120%) hue-rotate(100deg);
	}
	.video-pattern--spiral {
		filter: brightness(0.9) sepia(1) saturate(110%) hue-rotate(100deg);
	}
}

.character-interaction {
	@apply relative;
}
