.tadam-section--chess-kiki {
	@apply min-h-[100vh] pt-32 pb-44;

	h2 {
		@apply relative  w-fit;
		&:before,
		&:after {
			content: "";
			@apply absolute top-1/2 w-40 h-40 bg-no-repeat bg-center bg-contain;
			background-image: url("/assets/img/tadam/cavalier_2x.webp");
		}
		&:before {
			@apply top-1/2 left-0;
			transform: translate(-110%, -50%);
		}
		&:after {
			@apply right-0;
			transform: translate(110%, -50%) scaleX(-1);
		}
	}

	.chessboard {
		@apply bg-center mt-16  flex justify-center items-center relative;

		width: 100%;
		min-height: 100vh;
		/* width: 100%; */
		/* height: 100px; */
		/* background-image: url("/assets/img/tadam/chessboard_2x.webp"); */
		/* background-size: 100% 100%; */
		img {
			@apply absolute top-1/2 left-1/2 w-full h-full;
			width: 140%;
			height: 140%;
			transform: translate(-50%, -54%);
			/* object-fit: contain; */
		}
	}

	.chessboard-game-container {
		@apply bg-red-400 relative h-[900px] w-full;

		max-width: 470px;
		max-height: 470px;
		/* width: 74%;
		height: 80%;
		top: 18%;
		left: 50%; */
		/* // width: 74%; */

		aspect-ratio: 1/1;
		overflow: visible;

		@screen 2xl {
			@apply mt-32;
			scale: 1.6;
		}
	}
	.chessboard__game {
		@apply bg-emerald-200 absolute overflow-hidden;
		// width: 74%;
		height: 100%;
		max-width: 470px;
		max-height: 470px;
		aspect-ratio: 1/1;
		top: 0;
		left: 50%;
		transform: translate(-50%, 0%);
		overflow: hidden;

		iframe {
			@apply absolute top-0 left-1/2;
			filter: grayscale(0.7) hue-rotate(140deg) saturate(1.5);

			transform: scale(1.3);
			left: 50%;
			transform: translate(-50%, -20px);
			// width: 125%;
			// height: 125%;
			// scale: 1.15;
			aspect-ratio: 1/1;
			overflow: hidden;
			background-color: red;
			padding: 10px;
			height: 100px;
			width: 100px;

			width: 110%;
			height: 132%;
			scale: 1;

			// @screen md {
			// 	// transform: translate(-48.5%, -54%);
			// 	width: 122%;
			// 	// scale: 1.15;
			// 	height: 122%;
			// 	scale: 1;
			// }
			@screen xl {
				// transform: translate(-49%, -51.7%);
				width: 110%;
				height: 132%;
				scale: 1;
			}
		}
	}

	.testcrop {
		margin: 50px 0px;
		width: 450px;
		overflow: hidden;
		height: auto;
		aspect-ratio: 1 / 1;
		@apply relative;
		iframe {
			transform: translateY(0px);
			width: 100%;
		}
	}

	.colonne-green {
		@apply hidden xl:block absolute h-full w-auto bottom-0;

		&--left {
			@apply -left-20 2xl:left-0;
			/* transform: translate(-100%, 0%); */
		}
		&--right {
			@apply -right-20 2xl:right-0;
			transform: scaleX(-1);
			/* transform: translate(100%, 0%); */
		}
	}

	.vignette {
		@apply absolute bottom-0 left-0 w-full h-full;
		filter: brightness(1.09);
		z-index: -2;
	}
}
