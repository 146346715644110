// @tailwind base;
// @tailwind components;
// @tailwind utilities;

@import "./styles/generics/colors";
@import "./styles/apparitions.css";
@import "./styles/generics/typography";
@import "./styles/generics/ctas.scss";
$primary-blue: #2b07f3;

#root {
	@apply w-full;
}
.page_container {
	@apply w-full;
}

*,
html,
#root {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	padding: 0;
	// overflow: hidden !important;
}

h1 {
	text-align: center;
}
* {
	font-family: "din", sans-serif;
	font-weight: 200;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "myoliver", sans-serif;
	font-weight: 100;
	text-rendering: geometricPrecision;
}
a {
	text-decoration: none;
	color: unset;
}

.page_container.sky {
	background: #0087c6;
	background: -webkit-linear-gradient(top left, hsl(210, 100%, 39%), hsl(190, 81%, 67%));
	background: -moz-linear-gradient(top left, hsl(210, 100%, 39%), hsl(190, 81%, 67%));
	background: linear-gradient(to bottom right, hsl(210, 100%, 39%), hsl(190, 81%, 67%));
	background: linear-gradient(to bottom right, hsl(216, 100%, 39%), hsl(200, 81%, 67%));
	// background: linear-gradient(to bottom right, #0087c6, #4fb7d5);
	min-height: 100vh;
	// overflow: scroll;
}

// body:has(.modal) {
// 	overflow: scroll;
// 	max-height: 100vh;
// }
// body:has(header.burger_menu_active) {
// 	@apply max-h-screen;
// 	overflow: hidden;
// }
