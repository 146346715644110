@import "styles/generics/typography";

.header {
	@apply min-h-screen   flex flex-col justify-center items-center  relative pb-32;
	box-sizing: content-box;
	.arrow-scroll-down {
		@apply absolute bottom-32 left-1/2;
		transform: translateX(-50%);
		filter: invert(1);
		@apply w-12 h-32;
		z-index: 200;
	}
	h1 {
		@apply relative;
		&:before,
		&:after {
			content: "";
			@apply absolute bottom-2 w-20 h-20 bg-center bg-contain;
			background-image: url("/assets/img/tadam/star.webp");
			background-repeat: no-repeat;
		}
		&:before {
			@apply md:-left-8;
		}
		&:after {
			@apply md:-right-8;
		}
	}
}

.content-container {
	@apply px-12 xl:px-32 2xl:px-64 flex relative 
	flex-col justify-center items-center pb-24 mb-4
	bg-no-repeat;
	/* z-index: 20; */
	background-position: center;

	background-size: 200% 100%;
	@apply w-full;
	@screen md {
		background-size: 100% 100%;
	}
	@screen lg {
		background-size: 50% 100%;
	}

	.btn-change-character {
		@apply absolute top-1/2 -translate-y-1/2 flex   gap-4 items-center justify-center z-50 text-tadamblue;
		@apply bg-transparent border-none rounded-full md:rounded-none p-2 flex items-center justify-center md:opacity-70;
		@media (max-width: 1024px) {
			transform: translateY(-100px);
		}
		@media (max-width: 768px) {
			z-index: 200;
		}

		scale: 1;
		transition: scale 0.3s ease-in-out;

		&:hover {
			opacity: 1;
			span:after {
				transform: scaleX(1);
			}
		}

		span {
			@apply text-4xl 2xl:text-5xl  p-0 relative  tracking-wider font-normal;
			@apply text-renardblue;
			@apply hidden md:block;
			transition: opacity 0.3s ease-in-out;
			font-family: $magicretro;

			&:after {
				content: "";
				@apply absolute bottom-0 left-0 w-full bg-tadamblue;
				transform: scaleX(0);
				height: 1px;
				transition: transform 0.3s ease-in-out;
			}
		}
		.arrow-icon {
			@apply w-16 h-16 lg:w-8 lg:h-6 p-4 lg:p-0;
			@media (max-width: 768px) {
				filter: brightness(0) saturate(100%) invert(100%) sepia(76%) saturate(2%)
					hue-rotate(31deg) brightness(104%) contrast(100%) !important;
				display: block !important;
			}
		}

		&--previous {
			.arrow-icon {
				transform: scaleX(-1);
			}
			@apply left-2 md:left-8 lg:left-16 2xl:left-32;
		}
		&--next {
			@apply right-2 md:right-8 lg:right-16 2xl:right-32;
		}
		&:hover,
		&:focus-visible {
			scale: 1.5;
		}
	}
}

.title-banderolle-div {
	@apply absolute top-0 w-full h-80 block z-20;
	background-image: url("/assets/img/tadam/motif_titre_1_2.webp");
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
}
.header--yann {
	.content-container {
		background-image: url("/assets/img/tadam/textures/f1-yann.webp");
	}
	.arrow-scroll-down {
		filter: brightness(0) saturate(100%) invert(24%) sepia(36%) saturate(2878%) hue-rotate(222deg)
			brightness(91%) contrast(107%);
	}
	.content-container .btn-change-character {
		@apply bg-indigo-800 md:bg-transparent;
		.arrow-icon {
			filter: brightness(0) saturate(100%) invert(24%) sepia(36%) saturate(2878%) hue-rotate(222deg)
				brightness(91%) contrast(107%);
		}
		span {
			@apply text-indigo-800;
			&:after {
				@apply bg-indigo-800;
			}
		}
	}
	.test {
		@apply h-80 absolute  z-20;
		width: 870px;
		top: -120px;
		left: 50%;
		transform: translateX(-50%);
	}
	.text_title_test {
		@apply absolute left-1/2 top-20 z-50 max-w-full;
		width: 400px;
		transform: translateX(-50%);
	}
	.title-banderolle {
		// @apply absolute top-0 w-full h-full object-cover;
		// object-position: center top;
		// pointer-events: none;
		// z-index: 0;
		// background-color: red;
		@apply absolute left-0 top-0 w-full  object-cover;
		object-position: center top;
		pointer-events: none;
		z-index: 0;
		aspect-ratio: 1728/454;
		min-height: 354px;
	}

	.rideau {
		@apply absolute top-0  h-screen 2xl:h-[110vh] w-auto hidden md:block;

		&--left {
			@apply left-[-10%] lg:left-[-6%] xl:left-[-4%] absolute;
		}
		&--right {
			@apply right-[-10%] lg:right-[-6%] xl:right-[-4%] absolute;
			-webkit-transform: scaleX(-1);
			transform: scaleX(-1);
		}
	}

	.star {
		@apply hidden md:block absolute top-0 w-36;
		&--left {
			@apply left-0;
		}
		&--right {
			@apply right-0;
			-webkit-transform: scaleX(-1);
			transform: scaleX(-1);
		}
	}
	.video--chapeau {
		@apply 2xl:max-h-[30rem] max-h-64;
	}
}

.header--louison {
	.arrow-scroll-down {
		filter: brightness(0) saturate(100%) invert(9%) sepia(82%) saturate(5048%) hue-rotate(350deg)
			brightness(96%) contrast(100%);
	}
	h1 {
		&:before {
			@apply top-1/2 md:top-0 -left-16 md:-left-8;
			@media (max-width: 768px) {
				transform: translateY(-50%);
			}
		}
		&:after {
			@apply top-1/2 md:top-0 -right-16 md:-right-8;
			@media (max-width: 768px) {
				transform: translateY(-50%);
			}
		}
	}

	.content-container {
		background-image: url("/assets/img/tadam/textures/f1-louison.webp");
	}
	.content-container .btn-change-character {
		@apply bg-red-800 md:bg-transparent;
		.arrow-icon {
			filter: brightness(0) saturate(100%) invert(9%) sepia(82%) saturate(5048%) hue-rotate(350deg)
				brightness(96%) contrast(100%);
		}
		span {
			/* filter: hue-rotate(90deg) saturate(60%) brightness(1.2); */
			@apply text-red-800;
			&:after {
				@apply bg-red-800;
			}
		}
	}
	.video--sac {
		@apply 2xl:max-h-[30rem] max-h-64;
	}
	.guitare {
		@apply absolute top-0 left-0  h-screen w-auto hidden md:block;
		z-index: 1;
	}
	.eclair {
		@apply hidden md:block absolute top-0;
		height: 55vh;

		&--left {
			@apply left-0;
			transform: scaleX(-1);
		}
		&--right {
			@apply right-0;
		}
	}

	.damier-motif {
		@apply absolute top-0  opacity-100 hidden md:block;
		height: 80vh;
		z-index: 0;

		&--left {
			@apply left-0;
		}
		&--right {
			@apply right-0;
			transform: scaleX(-1);
		}
	}
}

.header--kiki {
	.video--crane {
		@apply 2xl:max-h-[30rem] max-h-64;
	}

	.escalier {
		@apply absolute bottom-0;
		height: 55vh;

		&--left {
			@apply left-0;
		}
		&--right {
			@apply right-0;
			transform: scaleX(-1);
		}
	}
	.bone-arm {
		@apply absolute top-0 z-30 hidden md:block;
		max-width: 500px;
		@screen md {
			width: 22vw;
			top: 18vh;
		}
		@screen 2xl {
			width: 30vw;
			top: 15vh;
		}

		&--left {
			@apply left-0;
		}
		&--right {
			@apply right-0;
			transform: scaleX(-1);
		}
	}
	.arrow-scroll-down {
		filter: brightness(0) saturate(100%) invert(25%) sepia(56%) saturate(694%) hue-rotate(113deg)
			brightness(100%) contrast(100%);
	}
	.content-container {
		background-image: url("/assets/img/tadam/textures/f1-kiki.webp");
	}
	.content-container .btn-change-character {
		@apply bg-emerald-800 md:bg-transparent;
		.arrow-icon {
			filter: brightness(0) saturate(100%) invert(25%) sepia(56%) saturate(694%) hue-rotate(113deg)
				brightness(100%) contrast(100%);
		}
		span {
			/* filter: hue-rotate(200deg) saturate(50%); */
			@apply text-emerald-800;
			&:after {
				@apply bg-emerald-800;
			}
		}
	}
}
